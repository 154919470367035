import React, { useState, useEffect } from "react";
import { v4 } from "uuid";
import moment from "moment";
import _ from "lodash";

import Constants from "../../constants/index";
import AdminActions from "../../actions/adminActions";
import AdminStore from "../../stores/adminStore";

import { Spinner } from "../../components/Spinner";
import { toast } from "react-toastify";

const ApplianceCard = (props) => {
  // passed props
  const {
    buildingId,
    passedApplianceData, // passed appliance data that is cloned and later compared to after edits
    // passed handlers
    cancelNewApplianceHandler,
  } = props;

  // skeleton data for the new appliance
  const NEW_APPLIANCE_DATA = {
    appliance_id: undefined,
    // pass down building ID from parent
    building_id: buildingId,
    uuid: v4(),
    mac_addr: "",
    name: "",
    description: "",
    status: "disabled",
    log_enabled: false,
  };

  // STATE
  const [isNewAppliance] = useState(!passedApplianceData);
  const [isExpanded, setIsExpanded] = useState(isNewAppliance);
  const [isEditable, setIsEditable] = useState(false || isNewAppliance);
  const [activeTextArea, setActiveTextArea] = useState(undefined);
  const [deletionMenu, toggleDeletionMenu] = useState(false);
  // check whether the appliance is pre-existing or new, picking data based on that
  const [applianceData, setApplianceData] = useState(
    isNewAppliance ? NEW_APPLIANCE_DATA : passedApplianceData
  );
  const [modifiedApplianceData, setModifiedApplianceData] = useState(
    _.cloneDeep(applianceData)
  );
  const [configFile, setConfigFile] = useState("");
  const [modifiedConfigFile, setModifiedConfigFile] = useState(configFile);
  const [blacklistFile, setBlacklistFile] = useState("");
  const [modifiedBlacklistFile, setModifiedBlacklistFile] =
    useState(blacklistFile);

  // LIFECYCLE METHODS AND DATA FETCHES

  // useffect to attach listeners checking for changes in store
  // Config is fetched when bottom menu is open
  // Once config is fetched _onConfigStore runs and sets state appropriately
  useEffect(() => {
    AdminStore.addStoreApplianceConfigFileListener(_onConfigStore);
    AdminStore.addStoreApplianceBlacklistFileListener(_onBlacklistStore);
    return () => {
      AdminStore.removeStoreApplianceConfigFileListener(_onConfigStore);
      AdminStore.removeStoreApplianceBlacklistFileListener(_onBlacklistStore);
      AdminStore.clear();
    };
  });

  // callback function to update the state that triggers on event
  const _onConfigStore = () => {
    const config = AdminStore.getConfigFile();
    if (config.uuid === modifiedApplianceData.uuid) {
      setConfigFile(config.data);
      setModifiedConfigFile(config.data);
    }
  };

  const _onBlacklistStore = () => {
    const blacklist = AdminStore.getBlacklistFile();
    // Component receives array from  store, but changes it to string for ease of formatting
    const blacklistData = blacklist.data
      ? blacklist.data.toString()
      : undefined;
    if (blacklist.uuid === modifiedApplianceData.uuid) {
      setBlacklistFile(blacklistData);
      setModifiedBlacklistFile(blacklistData);
    }
  };

  const _fetchApplianceConfigFile = () => {
    AdminActions.getApplianceConfigFile(applianceData.uuid);
  };

  const _fetchBlacklistFile = () => {
    AdminActions.getApplianceBlacklistFile(applianceData.uuid);
  };

  // HANDLERS AND HELPERS

  const _editButtonClickHandler = () => {
    setIsEditable(!isEditable);
    setIsExpanded(true);
  };

  const _deleteApplianceHandler = (appliance_id) => {
    AdminActions.deleteAppliance(appliance_id);
  };

  const _saveApplianceHandler = (appliance, config, blacklist) => {
    const saved_appliance = {
      building_id: buildingId,
      uuid: appliance.uuid,
      mac_addr: appliance.mac_addr,
      name: appliance.name,
      description: appliance.description,
      status: appliance.status,
      log_enabled: appliance.log_enabled,
    };
    AdminActions.saveAppliance(saved_appliance, config, blacklist);
    cancelNewApplianceHandler(false);
  };

  const _updateApplianceHandler = (appliance) => {
    AdminActions.updateAppliance(appliance);
  };

  const _saveConfigAndBlacklistFileHandler = (uuid, config, blacklist) => {
    AdminActions.saveApplianceConfigFile(uuid, config, blacklist);
  };

  // checks if the form is valid and compares initial data with the modified data to determine whether update is needed
  const _saveButtonClickHandler = () => {
    const modifiedBlacklistFileFiltered = modifiedBlacklistFile
      ? _transformBlacklistStringToArray(modifiedBlacklistFile)
      : undefined;

    if (
      _checkDataValidity() &&
      !_.isEqual(applianceData, modifiedApplianceData)
    ) {
      _saveApplianceHandler(
        modifiedApplianceData,
        modifiedConfigFile,
        modifiedBlacklistFileFiltered
      );
      setApplianceData(modifiedApplianceData);
      setConfigFile(modifiedConfigFile);
      setBlacklistFile(modifiedBlacklistFile);
    }
  };

  // checks for changes in appliance data, config file and blacklist, notifies user when no changes were detected
  const _updateButtonClickHandler = () => {
    // helper function that cleans the string and converts it to array
    const modifiedBlacklistFileFiltered = modifiedBlacklistFile
      ? _transformBlacklistStringToArray(modifiedBlacklistFile)
      : [];

    if (
      _checkDataValidity() &&
      !_.isEqual(applianceData, modifiedApplianceData)
    ) {
      _updateApplianceHandler(modifiedApplianceData);
      setApplianceData(modifiedApplianceData);
    }

    //
    if (!_.isEqual(blacklistFile, modifiedBlacklistFile)) {
      _saveConfigAndBlacklistFileHandler(
        modifiedApplianceData.uuid,
        modifiedConfigFile,
        modifiedBlacklistFileFiltered
      );
      setConfigFile(modifiedConfigFile);
      setBlacklistFile(
        modifiedBlacklistFileFiltered.length === 0
          ? undefined
          : modifiedBlacklistFile
      );
      setModifiedBlacklistFile(
        modifiedBlacklistFileFiltered.length === 0
          ? undefined
          : modifiedBlacklistFile
      );
    } else if (!_.isEqual(configFile, modifiedConfigFile)) {
      // look for blacklist in config, and if there is one, send it as a blacklist
      const regex = /(BACNET_BLACKLIST =\s*).*/gm;
      const configHasBlacklist = modifiedConfigFile.match(regex);
      let blacklist = undefined;

      if (configHasBlacklist) {
        blacklist = configHasBlacklist[0]
          .replace("BACNET_BLACKLIST =", "")
          .trim();
        blacklist = _transformBlacklistStringToArray(blacklist);
      }

      _saveConfigAndBlacklistFileHandler(
        modifiedApplianceData.uuid,
        modifiedConfigFile,
        blacklist
      );
      setConfigFile(modifiedConfigFile);
    }

    if (
      _.isEqual(applianceData, modifiedApplianceData) &&
      _.isEqual(configFile, modifiedConfigFile) &&
      _.isEqual(blacklistFile, modifiedBlacklistFile)
    ) {
      toast("No Changes Detected", {
        type: toast.TYPE.WARNING,
        autoClose: 3000,
        preventDuplicated: true,
      });
    }
  };

  const _cancelApplianceCreation = () => {
    cancelNewApplianceHandler();
  };

  // helper function that checks if the form is valid
  const _checkDataValidity = () => {
    const isNameValid =
      modifiedApplianceData.name.length >= 4 &&
      modifiedApplianceData.name.length <= 32;
    const isDescriptionValid =
      modifiedApplianceData.description.length >= 4 &&
      modifiedApplianceData.description.length <= 512;
    const isUniqueIdentifierValid =
      modifiedApplianceData.uuid.length > 0 &&
      modifiedApplianceData.uuid.length <= 36;
    const isMacValid =
      Constants.MAC_ADDRESS_PATTERN.test(modifiedApplianceData.mac_addr) ||
      modifiedApplianceData.mac_addr === "override" ||
      modifiedApplianceData.mac_addr === "unknown";
    return (
      isNameValid && isDescriptionValid && isMacValid && isUniqueIdentifierValid
    );
  };

  // helper function converting and cleaning up strings back to Array to send to back-end
  const _transformBlacklistStringToArray = (string) => {
    const regex = /(([,\s+]+[\s*])|[\n]|,(?=\S))/gm;
    const array =
      string &&
      string
        // replacing whitespaces, multiple newlines with coma and newline
        .replace(regex, ",\n")
        // creating the array
        .split(",\n")
        // filtering falsy elements
        .filter((element) => element)
        // removing coma from last element
        .map((element) => {
          if (element.slice(-1) === ",") {
            return element.slice(0, -1);
          }
          return element;
        });

    return [...array];
  };

  // expands the card after clicking the appliance label
  const _cardLabelClickHandler = () => {
    setIsExpanded(!isExpanded);
    if (isEditable && !isNewAppliance) setIsEditable(false);
  };

  // selects the file menu, blacklist or config file
  const _bottomMenuClickHandler = (menu) => {
    if (activeTextArea === menu) {
      setActiveTextArea(undefined);
    } else if (menu === "config") {
      setActiveTextArea("config");
      if (
        !isNewAppliance &&
        _.isEqual(configFile, modifiedConfigFile) &&
        _.isEqual(blacklistFile, modifiedBlacklistFile)
      )
        _fetchApplianceConfigFile();
    } else if (menu === "blacklist") {
      setActiveTextArea("blacklist");
      if (
        !isNewAppliance &&
        _.isEqual(configFile, modifiedConfigFile) &&
        _.isEqual(blacklistFile, modifiedBlacklistFile)
      )
        _fetchBlacklistFile();
    }
  };

  // helper function updating the cloned appliance data keys
  const _updateModifiedApplianceDataHandler = (e, key) => {
    const newValue = e.target.value;
    setModifiedApplianceData({
      ...modifiedApplianceData,
      [key]: newValue,
    });
  };

  const _configChangeHandler = (e) => {
    const newConfig = e.target.value;
    setModifiedConfigFile(newConfig);
  };

  const _blacklistChangeHandler = (e) => {
    const newBlacklist = e.target.value;
    setModifiedBlacklistFile(newBlacklist);
  };

  // FORM BODY

  const getFormBody = () => {
    return (
      <div className="card-body bg-transparent">
        <form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <div className="mb-3">
                <label className="mg-r-5">Name:</label>
                <input
                  value={modifiedApplianceData.name}
                  onChange={(e) =>
                    _updateModifiedApplianceDataHandler(e, "name")
                  }
                  readOnly={!isEditable}
                  className="form-control w-75"
                  type="text"
                  placeholder="min. 4 characters; max. 32 characters"
                  maxLength="32"
                />
              </div>
              <div className="mb-3">
                <label className="mg-r-5">Description:</label>
                <input
                  value={modifiedApplianceData.description}
                  onChange={(e) =>
                    _updateModifiedApplianceDataHandler(e, "description")
                  }
                  readOnly={!isEditable}
                  className="form-control w-75"
                  type="text"
                  placeholder="min. 4 characters; max. 512 characters"
                  maxLength="512"
                />
              </div>
              <div className="mb-3">
                <label className="mg-r-5">Unique Identifier:</label>
                <input
                  value={modifiedApplianceData.uuid}
                  className="form-control w-75"
                  type="text"
                  readOnly={!isEditable}
                  onChange={(e) =>
                    _updateModifiedApplianceDataHandler(e, "uuid")
                  }
                  placeholder="max. 36 characters."
                  maxLength="36"
                />
              </div>
              <div className="mb-3">
                <label className="mg-r-5">
                  MAC Address{" "}
                  <span style={{ fontSize: "10px" }}>
                    {" "}
                    (can be set to 'unknown')
                  </span>{" "}
                </label>
                <input
                  value={modifiedApplianceData.mac_addr}
                  onChange={(e) =>
                    _updateModifiedApplianceDataHandler(e, "mac_addr")
                  }
                  readOnly={!isEditable}
                  className="form-control w-75"
                  type="text"
                  maxLength="17"
                  placeholder="MAC address e.g b8:27:eb:36:b4:3f"
                />
              </div>
            </div>
            <div className="form-group col-md-6">
              <div className="mb-3">
                <label className="mg-r-5">Building ID:</label>
                <input
                  className="form-control w-75"
                  type="text"
                  placeholder="Building ID"
                  readOnly
                  value={modifiedApplianceData.building_id}
                />
              </div>
              <div className="mb-3">
                <label className="mg-r-5">Appliance ID:</label>
                <input
                  className="form-control w-75"
                  type="text"
                  placeholder="Appliance ID - generated automatically"
                  disabled
                  value={modifiedApplianceData.appliance_id}
                />
              </div>
              <div className="my-3 custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={modifiedApplianceData.uuid}
                  checked={modifiedApplianceData.status === "ready"}
                  disabled={!isEditable}
                  onChange={() =>
                    setModifiedApplianceData({
                      ...modifiedApplianceData,
                      status:
                        modifiedApplianceData.status === "ready"
                          ? "disabled"
                          : "ready",
                    })
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor={modifiedApplianceData.uuid}
                >
                  Appliance is {modifiedApplianceData.status || ""}.
                </label>
              </div>
              {/* LOGGING */}
              <div className="my-3 custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"logging" + modifiedApplianceData.uuid}
                  checked={modifiedApplianceData.log_enabled}
                  disabled={!isEditable}
                  onChange={() =>
                    setModifiedApplianceData({
                      ...modifiedApplianceData,
                      log_enabled: !modifiedApplianceData.log_enabled,
                    })
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor={"logging" + modifiedApplianceData.uuid}
                >
                  Appliance{" "}
                  {modifiedApplianceData.log_enabled ? "is" : "is not"} logging
                  data.
                </label>
              </div>
              {isNewAppliance ? null : (
                <div className="mb-3">
                  <p>
                    First Online:{" "}
                    {modifiedApplianceData.ts_first_online
                      ? moment(modifiedApplianceData.ts_first_online).format(
                          "MM-DD-YYYY, HH:mm"
                        )
                      : "N/A"}
                  </p>
                  <p>
                    Last Online:{" "}
                    {modifiedApplianceData.ts_last_online
                      ? moment(modifiedApplianceData.ts_last_online).format(
                          "MM-DD-YYYY, HH:mm"
                        )
                      : "N/A"}
                  </p>
                  <p>
                    Last Data Processed:{" "}
                    {modifiedApplianceData.ts_last_data
                      ? moment(modifiedApplianceData.ts_last_data).format(
                          "MM-DD-YYYY, HH:mm"
                        )
                      : "N/A"}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="mg-t-30 mg-lg-t-0 text-center d-flex justify-content-center my-auto">
            <button
              type="button"
              style={{ minWidth: "15%" }}
              className={`btn btn btn-${
                activeTextArea === "config" ? "" : "outline-"
              }info mx-3`}
              onClick={() => _bottomMenuClickHandler("config")}
            >
              Config File
            </button>
            <button
              type="button"
              style={{ minWidth: "15%" }}
              className={`btn btn btn-${
                activeTextArea === "blacklist" ? "" : "outline-"
              }info mx-3`}
              onClick={() => _bottomMenuClickHandler("blacklist")}
            >
              Blacklist
            </button>
            <button
              type="button"
              style={{ minWidth: "15%" }}
              disabled={!_checkDataValidity() || !isEditable}
              className={`btn mx-3 ${
                _checkDataValidity() ? "btn-success " : "btn-outline-success"
              }`}
              onClick={
                isNewAppliance
                  ? _saveButtonClickHandler
                  : _updateButtonClickHandler
              }
            >
              {isNewAppliance ? "Save" : "Update"}
            </button>
          </div>
        </form>
      </div>
    );
  };

  // CONFIG/BLACKLIST MENU

  const getFileSection = () => {
    let textArea = <Spinner />;

    //TextArea replaces Spinner when one of those conditions are true:
    // 1) It's a new appliance being created
    // 2) there is an existing config file that can populate it
    // 3) configFile is undefined, meaning appliance exists but has no config file attached to it
    if (
      activeTextArea === "config" &&
      (configFile ||
        configFile === undefined ||
        configFile === "" ||
        isNewAppliance)
    ) {
      textArea = (
        <textarea
          readOnly={!isEditable}
          value={modifiedConfigFile}
          placeholder={
            "Please enter appliance config file, blacklist can be edited here or in next tab"
          }
          onChange={_configChangeHandler}
          className="form-control tx-10"
          rows="12"
        />
      );
    } else if (
      activeTextArea === "blacklist" &&
      (blacklistFile ||
        blacklistFile === undefined ||
        blacklistFile === "" ||
        isNewAppliance)
    ) {
      // replacing newlines, spaces etc.
      const regex = /(([,\s+]+[\s*])|[\n]|,(?=\S))/gm;
      // parsing the array in column
      const stringifiedBlacklist = modifiedBlacklistFile
        ? modifiedBlacklistFile.replace(regex, ",\n")
        : "";
      textArea = (
        <textarea
          readOnly={!isEditable}
          value={stringifiedBlacklist}
          placeholder={"Use comas or new line to separate the items"}
          onChange={_blacklistChangeHandler}
          className="form-control tx-10"
          rows="12"
        />
      );
    }

    return (
      <div className="card-footer bg-white">
        <div className="form-group">{textArea}</div>
      </div>
    );
  };

  // EDIT/DELETE MAIN MENU

  const getMenuButtons = () => {
    let menu = (
      <>
        <button
          type="button"
          disabled
          style={{ minWidth: "30%", padding: "3px" }}
          className={`btn ${
            modifiedApplianceData.status === "ready"
              ? "btn-success"
              : "btn-secondary"
          }`}
        >
          {modifiedApplianceData.status === "ready" ? "Ready" : "Disabled"}
        </button>
        <button
          type="button"
          onClick={_editButtonClickHandler}
          style={{ minWidth: "30%", padding: "3px" }}
          className="btn btn-info "
        >
          {isEditable ? "Cancel" : "Edit"}
        </button>
        <button
          type="button"
          style={{ minWidth: "30%", padding: "3px" }}
          className="btn btn-danger"
          onClick={() => toggleDeletionMenu(true)}
        >
          Delete
        </button>
      </>
    );

    if (isNewAppliance)
      menu = (
        <button
          type="button"
          style={{ minWidth: "30%", marginLeft: "auto" }}
          className="btn btn-danger pd-0"
          onClick={() => _cancelApplianceCreation()}
        >
          Cancel
        </button>
      );

    if (deletionMenu)
      menu = (
        <>
          <button
            type="button"
            onClick={() =>
              _deleteApplianceHandler(modifiedApplianceData.appliance_id)
            }
            style={{ minWidth: "30%" }}
            className="btn btn-danger pd-3 mr-1"
          >
            Confirm Permanent Deletion
          </button>
          <button
            type="button"
            style={{ minWidth: "30%" }}
            className="btn btn-outline-danger pd-3"
            onClick={() => toggleDeletionMenu(false)}
          >
            Cancel
          </button>
        </>
      );

    return menu;
  };

  // CARD WRAPPER

  return (
    <div className="card shadow-base bd-0 mg-t-15 mg-b-15">
      <div className="card-header bg-transparent justify-content-between align-items-center border-bottom">
        <div className="row d-flex align-items-center">
          <div
            className="col-md-9 mg-t-30 mg-lg-t-0"
            onClick={_cardLabelClickHandler}
          >
            <div className="d-flex justify-content-between col-10 align-items-center">
              <h4 className="card-title col-3 tx-bold tx-uppercase tx-13 align-self-center mg-b-0">
                {modifiedApplianceData.name || "New Appliance"}
              </h4>
              <h4 className="card-subtitle col-3 text-muted tx-12 align-self-center mg-0">
                {modifiedApplianceData.description || "Appliance Description"}
              </h4>
              <h4 className="card-subtitle col-4 text-muted tx-12 align-self-center mg-0">
                <span>{modifiedApplianceData.uuid}</span>
              </h4>
            </div>
          </div>
          <div className="col-md-3 mg-t-15 mg-lg-t-0 text-center d-flex justify-content-between ">
            {getMenuButtons()}
          </div>
        </div>
      </div>
      {isExpanded ? getFormBody() : null}
      {activeTextArea && isExpanded ? getFileSection() : null}
    </div>
  );
};

export default ApplianceCard;
