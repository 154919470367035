import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { DatePicker, Button } from 'antd';
import styles from './TimeframePicker.module.scss';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Icon } from '../Icon';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import classNames from 'classnames';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
    weekStart: 1,
});

const { RangePicker } = DatePicker;
const DATE_FORMAT = 'YYYY-MM-DD';

const TimeframePicker = ({
    onChange,
    start,
    end,
    granularities = ['custom', 'day', 'week', 'month', 'quarter', 'year'],
    defaultGranularity = 'day',
    header = false,
    unix = false,
    disabled = false,
    spacing = '5px',
    placement = null,
    backgroundColor = '#fff',
    buttonColor = '#000',
    disableToday = false
}) => {
    const [open, setOpen] = useState(false);
    const [dateSelected, setDateSelected] = useState(false);
    const [pickerGranularity, setPickerGranularity] = useState(defaultGranularity);
    const [startGranularity, setStartGranularity] = useState(null);


    const pickerFormat = useMemo(() => ({
        custom: 'DD MMM YYYY',
        day: 'DD MMM YYYY',
        week: `DD MMM YYYY [- ${moment(unix ? start * 1000 : start).endOf('isoWeek').format('DD MMM YYYY')}]`,
        month: 'MMM YYYY',
        quarter: '[Q]Q-YYYY',
        year: 'YYYY',
    }), [start, unix]);

    const dateAdapter = useMemo(() => (date) => unix ? moment(date * 1000).format(DATE_FORMAT) : moment(date).format(DATE_FORMAT), [unix]);

    useEffect(() => {
        setPickerGranularity(defaultGranularity);
    }, [defaultGranularity]);

    // Useffect preventing granularity button flicker when switching rapidly through them
    useEffect(() => {
        let timeoutId;

        if (!open && !dateSelected) {
            timeoutId = setTimeout(() => {
                const debounced = _.debounce(() => {
                    setPickerGranularity(startGranularity ? startGranularity : defaultGranularity);
                    setStartGranularity(null);
                }, 0);

                debounced();
            }, 200);
        }

        return () => {
            clearTimeout(timeoutId);
        };
        // eslint-disable-next-line
    }, [dateSelected, open]);

    const handleDateChange = (date, dateString, selectedGranularity = null) => {
        let newStart, newEnd;
        const granularity = selectedGranularity || pickerGranularity;

        setDateSelected(true)
        setOpen(false)
        setStartGranularity(null)

        const formatDates = (rawStart, rawEnd, granularity) => {
            if (unix) {
                newStart = moment(rawStart).unix();
                newEnd = moment(rawEnd).endOf('day').unix();
            } else {
                newStart = moment(rawStart).format(DATE_FORMAT);
                newEnd = moment(rawEnd).format(DATE_FORMAT);
            }
        };

        if (granularity === 'custom' && date) {
            const rawStart = _.get(date[0], '$d');
            const rawEnd = _.get(date[1], '$d');
            formatDates(rawStart, rawEnd, granularity);
        } else if (granularity === 'week') {
            const rawDate = _.get(date, '$d');
            newStart = moment(rawDate).startOf('isoWeek').format(DATE_FORMAT);
            newEnd = moment(rawDate).endOf('isoWeek').format(DATE_FORMAT);
            if (unix) {
                newStart = moment(newStart).unix();
                newEnd = moment(newEnd).endOf('day').unix();
            }
        } else {
            const rawDate = _.get(date, '$d');
            newStart = moment(rawDate).startOf(granularity).format(DATE_FORMAT);
            newEnd = moment(rawDate).endOf(granularity).format(DATE_FORMAT);
            if (unix) {
                newStart = moment(newStart).unix();
                newEnd = moment(newEnd).endOf('day').unix();
            }
        }

        onChange(newStart, newEnd, granularity);
    };

    const handleGranularityChange = (granularity) => {
        if (!startGranularity) setStartGranularity(pickerGranularity);
        setPickerGranularity(granularity);
        handleOpenChange(true, granularity)
    };

    const handleNextPrevious = (direction) => {
        const displayStart = dateAdapter(start);
        const displayEnd = dateAdapter(end);
        let newStart, newEnd;

        const calculateNewDates = (unit) => {
            newStart = moment(displayStart)[direction](1, unit).format(DATE_FORMAT);
            newEnd = moment(displayEnd)[direction](1, unit).format(DATE_FORMAT);
        };

        if (pickerGranularity !== 'custom') {
            calculateNewDates(pickerGranularity);
            handleDateChange(dayjs(newStart), [newStart, newEnd]);
        } else {
            const daysBetween = dayjs(displayEnd).diff(dayjs(displayStart), 'day') || 1;
            newStart = moment(displayStart)[direction](daysBetween, 'day').format(DATE_FORMAT);
            newEnd = moment(displayEnd)[direction](daysBetween, 'day').format(DATE_FORMAT);
            handleDateChange([dayjs(newStart), dayjs(newEnd)], [newStart, newEnd]);
        }
    };
    const handleOpenChange = useCallback((status, granularity) => {
        setOpen(status);

        // Centering calendar
        const centerPopup = () => {
            const containerPrefix = styles.popupStyle;
            const inputPrefix = header ? styles.timeframePickerHeader : styles.timeframePicker;

            const getPopupElement = () => {
                let popup = document.querySelector(`.${containerPrefix}.ant-picker-dropdown-range`);
                if (!popup) {
                    popup = document.querySelector(`.${containerPrefix}.ant-picker-dropdown`);
                }
                return popup;
            };

            const getInputElement = (granularity) => {
                return granularity === 'custom'
                    ? document.querySelector(`.${inputPrefix} .ant-picker-range`)
                    : document.querySelector(`.${inputPrefix} .ant-picker-input`);
            };

            const setPopupPosition = (popup, inputRect, granularity) => {
                const viewportWidth = window.innerWidth;
                const popupWidth = popup.getBoundingClientRect().width || (granularity === 'custom' && viewportWidth >= 576 ? 576 : 288);
                let offsetLeft = inputRect.left + inputRect.width / 2 - popupWidth / 2;
                offsetLeft = Math.max(0, Math.min(offsetLeft, viewportWidth - popupWidth));

                // mobile adjustments
                if (viewportWidth < 576) {

                    const secondPanel = popup.querySelector(`.${containerPrefix} .ant-picker-panel:nth-child(2)`);
                    if (secondPanel) secondPanel.style.display = 'none';


                    const firstPanelButtons = popup.querySelectorAll(`.${containerPrefix} .ant-picker-panel:nth-child(1) button`);
                    firstPanelButtons.forEach((button) => {
                        button.style.visibility = 'visible';
                    });
                }

                popup.style.left = `${offsetLeft}px`;
                popup.style.top = `${inputRect.bottom + 6}px`;
            };

            const popup = getPopupElement();
            if (popup) {
                const input = getInputElement(granularity);

                if (input) {
                    const inputRect = input.getBoundingClientRect();
                    const arrow = popup.querySelector(`.${containerPrefix} .ant-picker-range-arrow`);
                    if (arrow) {
                        arrow.style.display = 'none';
                    }
                    setPopupPosition(popup, inputRect, granularity);
                }
            }
        };

        // Function to handle date change
        const dateChangeHandler = (cell) => {
            const listener = () => {
                const date = cell.getAttribute('title');
                let granularity = '';
                if (date.length === 10) {
                    granularity = 'day';
                } else if (date.length === 7) {
                    granularity = 'month';
                } else if (date.length === 4) {
                    granularity = 'year';
                }
                handleDateChange(dayjs(date), [date, date], granularity);
            };
            cell.addEventListener('click', listener);
            cell.listenerRef = listener; // Store the reference for later removal
        };

        if (status) {
            setTimeout(() => {
                if (!placement) centerPopup();
                // Add event listeners when popup opens
                const containerPrefix = styles.popupStyle;
                const selectedCells = document.querySelectorAll(`.${containerPrefix} .ant-picker-cell-selected`);
                selectedCells.forEach((c) => dateChangeHandler(c));
            }, 0);
            setDateSelected(false);
        } else {
            // Remove event listeners when popup closes
            const containerPrefix = styles.popupStyle;
            const selectedCells = document.querySelectorAll(`.${containerPrefix} .ant-picker-cell-selected`);
            selectedCells.forEach(cell => {
                const listener = cell.listenerRef;
                if (listener) {
                    cell.removeEventListener('click', listener);
                }
            });
        }
        // eslint-disable-next-line
    }, [header, open]);

    const disableFutureDates = useMemo(() => (current) => {
        // Disable future dates and optionally the current day
        return current && (current > dayjs().endOf('day') || (disableToday && current.isSame(dayjs(), 'day')));
    }, [disableToday]);

    const renderGranularityButtons = () => granularities.map((granularity) => (
        <Button
            key={granularity}
            size="small"
            onClick={() => handleGranularityChange(granularity)}
            type={pickerGranularity === granularity ? 'primary' : 'text'}
            className={classNames({
                [styles.activeButton]: pickerGranularity === granularity,
                [styles.inactiveButton]: pickerGranularity !== granularity,
            })}
        >
            {granularity.charAt(0).toUpperCase() + granularity.slice(1)}
        </Button>
    ));

    const renderArrow = (direction) => {
        const displayStart = dateAdapter(start);
        const displayEnd = dateAdapter(end);
        let newStart, newEnd, isFuture;

        if (start && end) {
            if (pickerGranularity === 'custom') {
                const daysBetween = dayjs(displayEnd).diff(dayjs(displayStart), 'day') || 1;
                newStart = moment(displayStart)[direction](daysBetween, 'day').format(DATE_FORMAT);
                newEnd = moment(displayEnd)[direction](daysBetween, 'day').format(DATE_FORMAT);
            } else {
                newStart = moment(displayStart)[direction](1, pickerGranularity).format(DATE_FORMAT);
                newEnd = moment(displayEnd)[direction](1, pickerGranularity).format(DATE_FORMAT);
            }

            // Check if the new start date is today and disableToday is true
            isFuture = moment(newStart).isAfter(moment(), 'day') || (disableToday && moment(newStart).isSame(moment(), 'day')) || (disableToday && moment(newEnd).isSame(moment(), 'day'));
        }

        return (
            <div className={styles.arrow} onClick={isFuture || !start || !end ? undefined : () => handleNextPrevious(direction)}>
                <Icon
                    name={`Arrow${direction === 'add' ? 'Right' : 'Left'}`}
                    color={isFuture ? '#c6c6c6' : buttonColor}
                    size={45}
                    style={{ cursor: isFuture ? 'not-allowed' : 'pointer' }} />
            </div>
        );
    }

    const renderPicker = () => {
        const displayStart = dateAdapter(start);
        const displayEnd = dateAdapter(end);

        if (pickerGranularity === 'custom') {
            return (
                <RangePicker
                    onChange={handleDateChange}
                    value={start && end ? [dayjs(displayStart), dayjs(displayEnd)] : [null, null]}
                    size="small"
                    variant="borderless"
                    className={styles.picker}
                    onOpenChange={handleOpenChange}
                    open={open}
                    popupClassName={open ? styles.popupStyle : styles.popupStyleHidden}
                    disabled={disabled}
                    separator="-"
                    disabledDate={disableFutureDates}
                    format={pickerFormat[pickerGranularity]}
                    placement={placement}
                />
            );
        } else {
            return (
                <DatePicker
                    value={start ? dayjs(displayStart) : null}
                    picker={pickerGranularity}
                    onChange={handleDateChange}
                    size="small"
                    variant="borderless"
                    className={styles.picker}
                    onOpenChange={handleOpenChange}
                    open={open}
                    popupClassName={open ? styles.popupStyle : styles.popupStyleHidden}
                    disabled={disabled}
                    disabledDate={disableFutureDates}
                    format={pickerFormat[pickerGranularity]}
                    placement={placement}
                />
            );
        }
    };

    return (
        <div className={header ? styles.timeframePickerHeader : styles.timeframePicker} style={{
            '--spacing': spacing,
            '--backgroundColor': backgroundColor,
            '--buttonColor': buttonColor,

        }}>
            <div className={styles.buttonWrapper}>
                {renderGranularityButtons()}
            </div>
            <div className={styles.pickerWrapper}>
                {renderArrow('subtract')}
                <div className={styles.pickerElement}>{renderPicker()}</div>
                {renderArrow('add')}
            </div>
        </div>
    );
};

TimeframePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    start: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    end: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    header: PropTypes.bool,
    unix: PropTypes.bool,
    spacing: PropTypes.string,
    backgroundColor: PropTypes.string,
    buttonColor: PropTypes.string,
    placement: PropTypes.oneOf(['topLeft', 'topRight', 'bottomLeft', 'bottomRight']),
    defaultGranularity: PropTypes.oneOf(['custom', 'day', 'week', 'month', 'quarter', 'year']),
    granularities: PropTypes.arrayOf(
        PropTypes.oneOf(['day', 'week', 'month', 'quarter', 'year', 'custom'])
    ),
    disableToday: PropTypes.bool
};

export default TimeframePicker;



