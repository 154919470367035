import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Table, Input } from 'antd';
import _ from 'lodash';
import styles from './AntTable.module.scss';
import classNames from 'classnames';

const AntTable = (props) => {
    const { columns, dataSource = [], tags, addNewButton, hasInputFilter, multiTagFilter, pagination = false, onRow, scroll = {}, ...rest } = props;
    const [inputFilter, setInputFilter] = useState('');
    const [selectedTag, setSelectedTag] = useState('All');
    const [tableHeight, setTableHeight] = useState(null);
    const [isNonScrollable, setIsNonScrollable] = useState(false);
    const wrapperRef = useRef(null);
    const filtersRef = useRef(null);

    const filterData = useCallback((filterValue, tagLabel) => {
        const lowercasedFilter = filterValue.toLowerCase();
        let filtered = dataSource;

        if (lowercasedFilter) {
            filtered = dataSource.filter(item => {
                return columns.some(column => {
                    if (column.filterable) {
                        const value = _.get(item, column.dataIndex);
                        return value && value.toString().toLowerCase().includes(lowercasedFilter);
                    }
                    return false;
                });
            });
        }

        if (tags && tags.length > 0) {
            const selectedTagObj = tags.find(tag => tag.label === tagLabel);
            if (selectedTagObj && selectedTagObj.condition) {
                filtered = filtered.filter(record => selectedTagObj.condition(record));
            }
        }

        return filtered;
    }, [dataSource, columns, tags]);

    const filteredData = filterData(inputFilter, selectedTag);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputFilter(value);
        filterData(value, selectedTag);
    };

    const handleTagClick = (label) => {
        setSelectedTag(label);
        filterData(inputFilter, label);
    };

    const getInputFilter = () => {
        return (
            <Input
                className={styles.inputFilter}
                placeholder={`Search in ${filteredData.length} entries...`}
                value={inputFilter}
                onChange={handleInputChange}
            />
        );
    };

    const getTagFilter = () => {
        return (
            <div className={styles.tagFilters}>
                {tags.map((tag) => {
                    let tagClasses = [styles.tag];
                    if (selectedTag === tag.label) {
                        tagClasses.push(styles.active);
                    }

                    return (
                        <div
                            key={tag.label}
                            className={tagClasses.join(' ')}
                            onClick={() => handleTagClick(tag.label)}
                        >
                            {tag.label}
                        </div>
                    );
                })}
            </div>
        );
    }

    // Modify columns to include custom sorter and render function
    const modifiedColumns = columns.map(column => {
        const newColumn = { ...column };

        // Wrap the existing render function or create a new one
        const originalRender = newColumn.render;
        newColumn.render = (text, record, index) => {
            const value = originalRender
                ? originalRender(text, record, index)
                : _.get(record, newColumn.dataIndex);

            return value === null || value === undefined
                ? <span style={{ opacity: 0.2 }}>N/A</span>
                : value;
        };

        // Add custom sorter if not present
        if (!newColumn.sorter) {
            newColumn.sorter = (a, b, sortOrder) => {
                const aValue = _.get(a, newColumn.dataIndex);
                const bValue = _.get(b, newColumn.dataIndex);

                // Always push null values to the bottom
                if (aValue === null || aValue === undefined) return sortOrder === 'ascend' ? 1 : -1;
                if (bValue === null || bValue === undefined) return sortOrder === 'ascend' ? -1 : 1;
                if (aValue === null && bValue === null) return 0;

                // Compare numbers
                if (typeof aValue === 'number' && typeof bValue === 'number') {
                    return aValue - bValue;
                }

                // Compare strings (case-insensitive, trimmed)
                return String(aValue).toLowerCase().trim().localeCompare(String(bValue).toLowerCase().trim());
            };
        }

        return newColumn;
    });

    useEffect(() => {
        const updateTableDimensions = () => {
            if (wrapperRef.current && filtersRef.current) {
                const wrapperWidth = wrapperRef.current.clientWidth;
                const wrapperHeight = wrapperRef.current.clientHeight;
                const filtersHeight = filtersRef.current.clientHeight;

                if (!scroll.y) {
                    setTableHeight(wrapperHeight - filtersHeight - 65);
                }

                setIsNonScrollable(scroll.x && wrapperWidth > scroll.x);
            }
        };

        updateTableDimensions();
        window.addEventListener('resize', updateTableDimensions);

        return () => window.removeEventListener('resize', updateTableDimensions);
    }, [scroll]);

    const tableScroll = scroll.y ? scroll : { ...scroll, y: tableHeight };

    return (
        <div className={styles.wrapper} ref={wrapperRef}>
            <div className={styles.filters} ref={filtersRef}>
                {hasInputFilter && getInputFilter()}
                {tags && getTagFilter()}
            </div>
            <Table
                className={classNames(
                    styles.table, 
                    { 
                        [styles.clickable]: !!onRow,
                        [styles.nonScrollable]: isNonScrollable
                    }
                )}
                columns={modifiedColumns}
                dataSource={filteredData}
                pagination={pagination}
                showSorterTooltip={false}
                onRow={onRow}
                scroll={tableScroll}
                {...rest}
            />
        </div>
    );
};

export default AntTable;