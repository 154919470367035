import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as Dashboard } from "../../assets/icons/dashboard.svg";
import { ReactComponent as Gas } from "../../assets/icons/gas-meter.svg";
import { ReactComponent as Zone } from "../../assets/icons/zone.svg";
import { ReactComponent as MeterExplorer } from "../../assets/icons/meter-explorer.svg";
import { ReactComponent as Hvac } from "../../assets/icons/hvac.svg";
import { ReactComponent as Password } from "../../assets/icons/password.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { ReactComponent as IndoorConditions } from "../../assets/icons/indoor-conditions.svg";
import { ReactComponent as Alarm } from "../../assets/icons/alarm.svg";
import { ReactComponent as Fan } from "../../assets/icons/fan.svg";
import { ReactComponent as Check } from "../../assets/icons/check.svg";
import { ReactComponent as CheckCircle } from "../../assets/icons/check-circle.svg";
import { ReactComponent as Cross } from "../../assets/icons/cross.svg";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as ToggleOn } from "../../assets/icons/toggle-on.svg";
import { ReactComponent as ToggleOff } from "../../assets/icons/toggle-off.svg";
import { ReactComponent as Warning } from "../../assets/icons/warning.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as DataExplorer } from "../../assets/icons/data-explorer.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as Analysis } from "../../assets/icons/analysis.svg";
import { ReactComponent as Euro } from "../../assets/icons/euro.svg";
import { ReactComponent as Co2 } from "../../assets/icons/co2.svg";
import { ReactComponent as AddCircle } from "../../assets/icons/add-circle.svg";
import { ReactComponent as AddCircleFilled } from "../../assets/icons/add-circle-filled.svg";
import { ReactComponent as Collapse } from "../../assets/icons/collapse.svg";
import { ReactComponent as Expand } from "../../assets/icons/expand.svg";
import { ReactComponent as Share } from "../../assets/icons/share.svg";
import { ReactComponent as RemoveSelection } from "../../assets/icons/remove-selection.svg";
import { ReactComponent as Save } from "../../assets/icons/save.svg";
import { ReactComponent as EditSquare } from "../../assets/icons/edit-square.svg";
import { ReactComponent as EditOff } from "../../assets/icons/edit-off.svg";
import { ReactComponent as Info } from "../../assets/icons/info.svg";
import { ReactComponent as Copy } from "../../assets/icons/copy.svg";
import { ReactComponent as Validated } from "../../assets/icons/validated.svg";
import { ReactComponent as Reset } from "../../assets/icons/reset.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";

const Icon = ({ name, size = 20, color = "#fdfdfb", style, onClick }) => {
  const icons = {
    Dashboard,
    Gas,
    Zone,
    MeterExplorer,
    Hvac,
    Password,
    Logout,
    IndoorConditions,
    Alarm,
    Fan,
    Check,
    CheckCircle,
    Cross,
    Delete,
    Edit,
    ToggleOn,
    ToggleOff,
    Warning,
    ArrowLeft,
    ArrowRight,
    ArrowUp,
    ArrowDown,
    DataExplorer,
    Close,
    Analysis,
    Euro,
    Co2,
    AddCircle,
    AddCircleFilled,
    Collapse,
    Expand,
    Share,
    RemoveSelection,
    Save,
    EditSquare,
    EditOff,
    Info,
    Copy,
    Validated,
    Reset,
    Download
  };

  const IconComponent = icons[name];

  if (!IconComponent) {
    return "Icon Error, check name/imports";
  }

  return (
    <IconComponent width={size} height={size} fill={color} style={style} onClick={onClick} />
  );
};

Icon.propTypes = {
  name: PropTypes.oneOf([
    "Dashboard",
    "Gas",
    "Zone",
    "MeterExplorer",
    "Hvac",
    "Password",
    "Logout",
    "IndoorConditions",
    "Alarm",
    "Fan",
    "Check",
    "CheckCircle",
    "Cross",
    "Delete",
    "Edit",
    "ToggleOn",
    "ToggleOff",
    "Warning",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
    "DataExplorer",
    "Close",
    'Analysis',
    'Euro',
    'Co2',
    'AddCircle',
    'AddCircleFilled',
    'Collapse',
    'Expand',
    'Share',
    'RemoveSelection',
    'Save',
    'EditSquare',
    'EditOff',
    'Info',
    'Copy',
    'Validated',
    'Reset'
  ]).isRequired,
  onClick: PropTypes.func,
  size: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.object,
};

export default Icon;

// Icons are sourced from: https://fonts.google.com/icons?icon.set=Material+Symbols&icon.style=Rounded&icon.platform=web
// Remove svg fill before importing
// Add to import -> const icons -> PropTypes
