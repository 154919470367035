import React from "react";
import styles from "./Meters.module.scss";
import Constants from "../../../constants";
import _ from "lodash";

//components
import { DocumentTitle } from "../../../components/DocumentTitle";
import { LogoSpinner } from "../../../components/LogoSpinner";
import { AntTable } from "../../../components/AntTable";
import GeneralUtils from "../../../utils/GeneralUtils";
import GeneralStore from "../../../stores/generalStore";
import MetersOverviewStore from "../../../stores/metersOverviewStore";
import MetersOverviewActions from "../../../actions/metersOverviewActions";

class Meters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      ts_start: GeneralStore.getStartDate(),
      ts_end: GeneralStore.getEndDate(),
      loading: true,
    };

    this.onMetersFetch = this.onMetersFetch.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.rowClickHandler = this.rowClickHandler.bind(this);
  }

  componentWillMount() {
    MetersOverviewStore.addMetersOverviewFetchListener(this.onMetersFetch);
    GeneralStore.addChangeListener(this.onDateChange);
  }

  componentWillUnmount() {
    MetersOverviewStore.removeMetersOverviewFetchListener(this.onMetersFetch);
    GeneralStore.removeChangeListener(this.onDateChange);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const start = GeneralUtils.getFormattedDate(this.state.ts_start);
    const end = GeneralUtils.getFormattedDate(this.state.ts_end, true);

    this.setState(
      {
        loading: true,
      },
      () => MetersOverviewActions.getMetersOverview(start, end)
    );
  }

  onMetersFetch() {
    const data = MetersOverviewStore.getMetersOverview();
    this.setState({ data: data, loading: false });
  }

  onDateChange() {
    this.setState(
      {
        ts_start: GeneralStore.getStartDate(),
        ts_end: GeneralStore.getEndDate(),
      },
      () => this.fetchData()
    );
  }

  getMeterNameWithVRTag(entry) {
    const name = _.get(entry, "meter.name");
    const virtual_calc = _.get(entry, "meter.virtual_calc");
    let vr_tag = "";

    if (virtual_calc) {
      vr_tag = (
        <span
          style={{
            background: "#347C93",
            color: "white",
            borderRadius: "12px",
            padding: "3px 5px",
            fontSize: "10px",
            marginLeft: "5px",
            verticalAlign: "middle",
          }}
        >
          Virtual
        </span>
      );
    }

    if (name === null) {
      return (
        <div className="tx-14 not-available">
          N/A
        </div>
      );
    }

    return (
      <div className="tx-14">
        {name}
        {vr_tag}
      </div>
    );
  }

  getConsumptionCell(entry) {
    const consumption = _.get(entry, "energy.consumption");
    const type = _.get(entry, "meter.type");
    let unit = " kWh";

    if (type && type.includes("WATER_METER")) {
      unit = " litres";
    }

    if (consumption === null) {
      return (
        <div className="tx-14 not-available">
          N/A
        </div>
      );
    }

    return (
      <div className="tx-14">
        {GeneralUtils.getFormattedNumberWithUnit(consumption, unit, 0)}
      </div>
    );
  }

  rowClickHandler(entry) {
    const building_id = _.get(entry, "building.id");
    const entity_id = _.get(entry, "entity.id");

    if (building_id && entity_id) {
      sessionStorage.setItem("meterLastLoc", this.props.location.pathname);
      this.props.history.push("/meters/" + entity_id);
    }
  }

  getTable() {
    const columns = [
      {
        title: 'Building Name',
        dataIndex: ['building', 'name'],
        key: 'buildingName',
        defaultSortOrder: 'ascend',
        width: '20%',
        filterable: true,
      },
      {
        title: 'Meter Name',
        dataIndex: ['meter', 'name'],
        key: 'meterName',
        width: '20%',
        filterable: true,
        render: (text, record) => {
          return this.getMeterNameWithVRTag(record)
        }
      },
      {
        title: 'Type',
        dataIndex: ['meter', 'type'],
        key: 'meterType',
        width: '15%',
        render: (text) => _.get(Constants, `METER_TYPES.${text}.label`, null),
      },
      {
        title: 'Consumption',
        dataIndex: ['energy', 'consumption'],
        key: 'energyConsumption',
        width: '15%',
        render: (text, record) => {
          return text === null ? null : this.getConsumptionCell(record)
        }
      },
      {
        title: 'Emissions',
        dataIndex: ['energy', 'emissions'],
        key: 'energyEmissions',
        width: '15%',
        render: (text) => {
          return text === null ? null : GeneralUtils.getFormattedNumberWithUnit(text, " kgCO2e")
        }
      },
      {
        title: 'Cost',
        dataIndex: ['energy', 'cost'],
        key: 'energyCost',
        width: '15%',
        render: (text) => {
          return text === null ? null : "€" + GeneralUtils.getFormattedNumberWithUnit(text, "")
        }
      }
    ];

    const tags = [
      {
        label: "All",
        condition: function (record) {
          return !!record
        },
        default: true,
      },
      {
        label: "Electric",
        condition: function (record) {
          return record.meter.type && record.meter.type.includes("ELECTRIC")
        },
      },
      {
        label: "Gas",
        condition: function (record) {
          return record.meter.type && record.meter.type.includes("GAS")
        },
      },
      {
        label: "Water",
        condition: function (record) {
          return record.meter.type && record.meter.type.includes("WATER")
        },
      },
      {
        label: "Heat",
        condition: function (record) {
          return record.meter.type && record.meter.type.includes("HEAT")
        },
      },
    ]

    return <AntTable
      columns={columns}
      dataSource={this.state.data}
      hasInputFilter={true}
      virtual
      rowKey={(record) => `${record.meter.id}`}
      scroll={{ x: 1000 }}
      onRow={(record) => ({
        onClick: () => this.rowClickHandler(record),
      })}
      tags={tags}
    />
  }

  render() {
    return (
      <div
        id="Meters"
        className="br-mainpanel br-profile-page floorplan-background"
        style={{ scrollY: "scroll" }}
      >
        <DocumentTitle title="Meters Overview" />
        <LogoSpinner loading={this.state.loading} />
        <div className="br-container">
          <div className="row">
            <div className={`col mg-t-30 d-flex ${styles.tableWrapper}`}>
              {this.getTable()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Meters;
