import Constants from "../constants";
import moment from "moment";
import API from "../api";
import Dispatcher from "../dispatcher";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Utility functions for general use throughout the application. This class provides a variety of
 * methods for data validation, formatting, and making API requests, among other tasks.
 *
 * @class
 * @example
 * const utils = new GeneralUtils();
 * utils.emailIsValid('test@example.com'); // Returns true if the email is valid
 */
class GeneralUtils {
  /**
   * Validates if the provided email string is in a valid email format.
   *
   * @param {string} email - The email string to validate.
   * @returns {boolean} - Returns true if the email is valid, otherwise false.
   */
  emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  /**
   * Checks if a given string is not empty.
   *
   * @param {string} string - The string to check.
   * @returns {boolean} - Returns true if the string is not empty, otherwise false.
   */
  stringNotEmpty(string) {
    return string && string.length > 0;
  }

  /**
   * Rounds a number to the specified number of decimal places.
   *
   * @param {number} value - The number to round.
   * @param {number} decimals - The number of decimal places to round to.
   * @returns {number} - The rounded number.
   */
  roundNumber(value, decimals) {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  }

  /**
   * Rounds a date to the nearest specified duration using the specified method.
   *
   * @param {Date} date - The date to round.
   * @param {number} duration - The duration to round to (e.g., an hour, day).
   * @param {string} method - The rounding method to use (e.g., "ceil", "floor").
   * @returns {moment.Moment} - The rounded date as a moment object.
   */
  roundDate(date, duration, method) {
    return moment(Math[method](+date / +duration) * +duration);
  }

  /**
   * Returns the ordinal numeral for a given number (e.g., 1st, 2nd, 3rd).
   *
   * @param {number} number - The number to convert to an ordinal numeral.
   * @returns {string} - The ordinal numeral as a string.
   */
  getOrdinalNumeral(number) {
    const suffix = ["th", "st", "nd", "rd"];
    const modulus = number % 100;
    return number + (suffix[(modulus - 20) % 10] || suffix[modulus] || suffix[0]);
  }

  /**
   * Determines the asset type of a given asset.
   *
   * @param {object} asset - The asset object to determine the type of.
   * @returns {string} - The asset type as a string.
   */
  getAssetType(asset) {
    if (asset && asset.asset_type && asset.asset_type.type) {
      if (Constants.ZONE_ASSETS.indexOf(asset.asset_type.type) > -1) {
        return "zone";
      } else if (
        Constants.AIR_HANDLING_ASSETS.indexOf(asset.asset_type.type) > -1
      ) {
        return "air_handling";
      } else if (Constants.HEATING_ASSETS.indexOf(asset.asset_type.type) > -1) {
        return "heating";
      } else if (Constants.COOLING_ASSETS.indexOf(asset.asset_type.type) > -1) {
        return "cooling";
      } else if (Constants.SENSOR_ASSETS.indexOf(asset.asset_type.type) > -1) {
        return "sensor";
      } else {
        return asset.asset_type.type;
      }
    } else {
      return asset.asset_type.type;
    }
  }

  /**
   * Generates an explanatory text for an alarm configuration.
   *
   * @param {object} params - The parameters for the alarm.
   * @returns {string} - The explanatory text for the alarm.
   */
  getAlarmExplainerText({
    name,
    target_period,
    target_factor,
    target_amount,
    target_type,
    target_category,
    required_triggers,
  }) {
    let unit = "%";
    let typeDesc = "...";

    if (target_factor === "energy consumption") {
      unit = "kWh";
      typeDesc = "energy consumption";
    } else if (target_factor === "energy reactive power") {
      unit = "kvar";
      typeDesc = "reactive power";
    } else if (target_factor === "energy active power") {
      unit = "kW";
      typeDesc = "active power";
    } else if (target_factor === "wellness temperature") {
      unit = "°C";
      typeDesc = "zone temperature";
    } else if (target_factor === "wellness humidity") {
      unit = "%";
      typeDesc = "zone humidity";
    } else if (target_factor === "wellness CO2") {
      unit = "ppm";
      typeDesc = "zone CO2 level";
    } else if (target_factor === "utilization percent") {
      unit = "%";
      typeDesc = "space occupancy";
    }

    let targetPeriodText =
      target_period === "every day"
        ? "on any day"
        : "on " + target_period + "s only";

    let text =
      name +
      " will alert " +
      targetPeriodText +
      " when the " +
      typeDesc +
      " exceeds the fixed target amount of " +
      target_amount +
      unit +
      " for more than " +
      required_triggers +
      " measurement(s).";

    if (target_type === "fixed_less") {
      text =
        name +
        " will alert " +
        targetPeriodText +
        " when the " +
        typeDesc +
        " falls below the fixed target amount of " +
        target_amount +
        unit +
        " for more than " +
        required_triggers +
        " measurement(s)";
    }

    if (target_category === "total") {
      text +=
        target_type === "fixed_less"
          ? " at the end of the day"
          : " over the course of the day";
    }

    return text;
  }

  /**
   * Gets a color based on a score value.
   *
   * @param {number} value - The score value to determine the color for.
   * @returns {string} - The color corresponding to the score.
   */
  getColorForScore(value) {
    if (value < 50) {
      return Constants.RED;
    } else if (value >= 80) {
      return Constants.GREEN;
    } else {
      return Constants.ORANGE;
    }
  }

  /**
   * Gets a transparent color based on a score value.
   *
   * @param {number} value - The score value to determine the transparent color for.
   * @returns {string} - The transparent color corresponding to the score.
   */
  getTransparentColorForScore(value) {
    if (value < 50) {
      return Constants.RED_TRANSPARENT;
    } else if (value >= 80) {
      return Constants.GREEN_TRANSPARENT;
    } else {
      return Constants.ORANGE_TRANSPARENT;
    }
  }

  /**
   * Gets a color based on a value type and its value.
   *
   * @param {string} valueType - The type of value (e.g., "temperature").
   * @param {number} value - The value to determine the color for.
   * @returns {string} - The color corresponding to the value type and value.
   */
  getColorForValue(value_type, value) {
    if (value_type === "temperature") {
      if (value <= 18 || value >= 24) {
        return Constants.RED;
      } else if (value <= 19 || value >= 23) {
        return Constants.ORANGE;
      } else {
        return Constants.GREEN;
      }
    } else if (value_type === "humidity") {
      if (value < 30 || value > 70) {
        return Constants.RED;
      } else if (value < 40 || value > 60) {
        return Constants.ORANGE;
      } else {
        return Constants.GREEN;
      }
    } else if (value_type === "co2") {
      if (value >= 1400) {
        return Constants.RED;
      } else if (value >= 1000) {
        return Constants.ORANGE;
      } else {
        return Constants.GREEN;
      }
    } else if (value_type === "cooling_component") {
      if (value >= 13) {
        return Constants.RED;
      } else if (value >= 11) {
        return Constants.ORANGE;
      } else {
        return Constants.GREEN;
      }
    } else if (value_type === "heating_component") {
      if (value <= 50) {
        return Constants.RED;
      } else if (value <= 55) {
        return Constants.ORANGE;
      } else {
        return Constants.GREEN;
      }
    } else {
      if (Math.abs(value) >= 5) {
        return Constants.RED;
      } else if (Math.abs(value) >= 2) {
        return Constants.ORANGE;
      } else {
        return Constants.GREEN;
      }
    }
  }

  /**
   * Formats a number with a unit, fraction digits, and locale.
   *
   * @param {number} value - The number to format.
   * @param {string} [unit=""] - The unit to append to the formatted number.
   * @param {number} [fractionDigits=0] - The number of fraction digits to include.
   * @param {string} [locale] - The locale for formatting.
   * @returns {string} - The formatted number with unit.
   */
  getFormattedNumberWithUnit(value, unit = "", fractionDigits = 0, locale) {
    if (isNaN(value)) {
      return "-";
    } else {
      return `${Constants.Numberformatter(fractionDigits, locale).format(
        value
      )}${unit}`;
    }
  }

  /**
   * Formats a date to the specified format.
   *
   * @param {number} date - The date timestamp to format.
   * @param {boolean} [isExclusive=false] - Whether to add a day for inclusive-exclusive bounds.
   * @param {string} [format="YYYY-MM-DD"] - The date format.
   * @returns {string} - The formatted date.
   */
  getFormattedDate(date, isExclusive = false, format = "YYYY-MM-DD") {
    // isExclusive pushes the day forward due to inclusive-exclusive timeframe bounds
    if (isExclusive) return moment.unix(date).add(1, "days").format(format);
    return moment.unix(date).format(format);
  }

  /**
   * Converts minutes to hours and minutes.
   *
   * @param {number} mins - The number of minutes to convert.
   * @returns {string} - The hours and minutes string.
   */
  getHoursFromMinutes(mins) {
    if (mins === 0) return "0 mins";
    if (mins < 60) return `0:${mins} hrs`;
    const hours = mins / 60;
    const rounded_hours = Math.floor(hours);
    const minutes = (hours - rounded_hours) * 60;
    let rounded_minutes = Math.round(minutes);
    if (rounded_minutes === 0) rounded_minutes = "00";
    return rounded_hours + ":" + rounded_minutes + " hrs";
  }

  /**
   * Converts minutes to hours and minutes.
   *
   * @param {number} mins - The number of minutes to convert.
   * @returns {string} - The hours and minutes string.
   */
  getDifferenceBetweenDates(timestamp1, timestamp2, period = "days") {
    const date1 = moment.unix(timestamp1);
    const date2 = moment.unix(timestamp2);
    const daysDiff = date2.diff(date1, period);
    return Math.abs(daysDiff);
  }

  /**
   * Copies a given text to the clipboard and shows a toast notification.
   *
   * @param {string} text - The text to copy to the clipboard.
   * @param {string} [toastMsg="Link Copied"] - The toast message to display.
   */
  copyToClipboard(text, toastMsg = "Link Copied") {
    navigator.clipboard.writeText(text).then(() => {
      if (toastMsg) {
        toast(toastMsg, {
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
          preventDuplicated: true,
        });
      }
    });
  }

  /**
 * Creates a background and font color from string.
 *
 * @param {string} inputString - The text to use to create colors.
 */
  getColorFromString(inputString) {
    let hash = 0;
    for (let i = 0; i < inputString.length; i++) {
      hash = inputString.charCodeAt(i) + ((hash << 5) - hash);
    }
    let bgColor = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF;
      bgColor += ('00' + value.toString(16)).substr(-2);
    }

    // Calculate luminance
    const r = parseInt(bgColor.substr(1, 2), 16);
    const g = parseInt(bgColor.substr(3, 2), 16);
    const b = parseInt(bgColor.substr(5, 2), 16);
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    // Determine font color based on luminance
    const fontColor = luminance > 128 ? 'black' : 'white';

    return { background: bgColor, color: fontColor };
  }

  /**
* Creates initials from given name.
*
* @param {string} name - Name for initials.
*/
  getInitials(name) {
    const splitName = name.toUpperCase().split(' ');
    if (splitName.length > 1) {
      return `${splitName[0][0]}${splitName[1][0]}`;
    } else {
      return splitName[0][0];
    }
  }

  /**
   * Interpolates a color from a gradient based on a percentage.
   *
   * @param {Array} gradient - The gradient array with positions and colors.
   * @param {number} percentage - The percentage value to interpolate.
   * @returns {string} - The interpolated color in hex format.
   */
  getColorFromGradient(gradient, percentage) {
    // Ensure the gradient has at least two colors
    if (gradient.length < 2) {
      throw new Error('Gradient must have at least two colors');
    }

    // Clamp percentage between 0 and 100
    const clampedPercentage = Math.max(0, Math.min(100, percentage));

    // If the percentage is 0 or 100, return the first or last color
    if (clampedPercentage === 0) return gradient[0].color;
    if (clampedPercentage === 100) return gradient[gradient.length - 1].color;

    // Find the two colors to interpolate between
    let startColor, endColor, startPercentage, endPercentage;
    for (let i = 0; i < gradient.length - 1; i++) {
      if (clampedPercentage >= gradient[i].position && clampedPercentage <= gradient[i + 1].position) {
        startColor = gradient[i].color;
        endColor = gradient[i + 1].color;
        startPercentage = gradient[i].position;
        endPercentage = gradient[i + 1].position;
        break;
      }
    }

    // Calculate the interpolation factor
    const range = endPercentage - startPercentage;
    const factor = (clampedPercentage - startPercentage) / range;

    // Interpolate between the two colors
    const r = Math.round(parseInt(startColor.slice(1, 3), 16) * (1 - factor) + parseInt(endColor.slice(1, 3), 16) * factor);
    const g = Math.round(parseInt(startColor.slice(3, 5), 16) * (1 - factor) + parseInt(endColor.slice(3, 5), 16) * factor);
    const b = Math.round(parseInt(startColor.slice(5, 7), 16) * (1 - factor) + parseInt(endColor.slice(5, 7), 16) * factor);

    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  }



  /**
 * Calculates the contrast color based on the given hex color.
 *
 * @param {string} hexColor - The hex color to calculate the contrast for.
 * @returns {string} - The contrast color (black or white).
 */
  getContrastColor(hexColor) {
    hexColor = hexColor.replace('#', '');

    // Convert hex to RGB
    const r = parseInt(hexColor.substr(0, 2), 16);
    const g = parseInt(hexColor.substr(2, 2), 16);
    const b = parseInt(hexColor.substr(4, 2), 16);

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Return black for bright colors, white for dark colors
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
  };

  /**
   * Makes a POST request to the specified URL, optionally dispatches an action, shows toasts, and resolves with the response.
   *
   * @param {string} options.url - The URL to make the POST request to.
   * @param {object} options.object - The object to post.
   * @param {string} [options.actionType] - The action type to dispatch with the response.
   * @param {function} [options.modifyResponse] - A function to modify the response before dispatching and resolving.
   * @param {string} [options.successToast] - A success message to show as a toast notification.
   * @param {string} [options.failToast] - A failure message to show as a toast notification.
   * @returns {Promise} A promise that resolves with the response.
   */
  post({ url, object, actionType, modifyResponse, successToast, failToast }) {
    return new Promise((resolve, reject) => {
      API.post(url, object)
        .then((response) => {

          if (response && (response.code === undefined || response.code === 200)) {
            // Apply the modifyResponse function if provided
            if (modifyResponse && typeof modifyResponse === "function") {
              response.data = modifyResponse(response.data);
            }

            // Dispatch only if actionType is present
            if (actionType) {
              Dispatcher.dispatch({
                actionType: actionType,
                payload: response.data,
              });
            }

            // Display toast if toast message is specified
            if (successToast) {
              toast(successToast, {
                type: toast.TYPE.SUCCESS,
                autoClose: 3000,
              });
            }
            resolve(response.data);
          } else {
            // Display toast if toast message is specified
            if (failToast) {
              toast(failToast, {
                type: toast.TYPE.ERROR,
                autoClose: 3000,
              });
            }
            reject(response || "Error fetching data");
          }
        })
        .catch((error) => {
          toast("Network or server error", {
            type: toast.TYPE.ERROR,
            autoClose: 3000,
          });
          reject(error);
        });
    });
  }

  /**
   * Makes a PUT request to the specified URL, optionally dispatches an action, shows toasts, and resolves with the response.
   *
   * @param {string} options.url - The URL to make the PUT request to.
   * @param {object} options.object - The object to put.
   * @param {string} [options.actionType] - The action type to dispatch with the response.
   * @param {string} [options.successToast] - A success message to show as a toast notification.
   * @param {string} [options.failToast] - A failure message to show as a toast notification.
   * @returns {Promise} A promise that resolves with the response.
   */
  put({ url, object, actionType, successToast, failToast }) {
    return new Promise((resolve, reject) => {
      API.put(url, object)
        .then((response) => {
          if (response && (response.code === undefined || response.code === 200)) {
            // Dispatch only if actionType is present
            if (actionType) {
              Dispatcher.dispatch({
                actionType: actionType,
                payload: response.data,
              });
            }

            // Display toast if toast message is specified
            if (successToast) {
              toast(successToast, {
                type: toast.TYPE.SUCCESS,
                autoClose: 3000,
              });
            }
            resolve(response.data);
          } else {
            // Display toast if toast message is specified
            if (failToast) {
              toast(failToast, {
                type: toast.TYPE.ERROR,
                autoClose: 3000,
              });
            }
            reject(response || "Error fetching data");
          }
        })
        .catch((error) => {
          toast("Network or server error", {
            type: toast.TYPE.ERROR,
            autoClose: 3000,
          });
          reject(error);
        });
    });
  }

  /**
   * Makes a GET request to the specified URL, optionally dispatches an action, shows toasts, and resolves with the response.
   *
   * @param {object} options - The options for the GET request.
   * @param {string} options.url - The URL to make the GET request to.
   * @param {string} [options.actionType] - The action type to dispatch with the response.
   * @param {function} [options.modifyResponse] - A function to modify the response before dispatching and resolving.
   * @param {string} [options.successToast] - A success message to show as a toast notification.
   * @param {string} [options.failToast] - A failure message to show as a toast notification.
   * @returns {Promise} A promise that resolves with the response.
   */
  get({ url, actionType, modifyResponse, successToast, failToast }) {
    return new Promise((resolve, reject) => {
      API.get(url)
        .then((response) => {
          if (response && (response.code === undefined || response.code === 200)) {
            // Apply the modifyResponse function if provided
            if (modifyResponse && typeof modifyResponse === "function") {
              response = modifyResponse(response);
            }

            if (actionType) {
              Dispatcher.dispatch({
                actionType: actionType,
                payload: response,
              });
            }

            // Display toast if toast message is specified
            if (successToast) {
              toast(successToast, {
                type: toast.TYPE.SUCCESS,
                autoClose: 3000,
              });
            }
            resolve(response);
          } else {
            // Display toast if toast message is specified
            if (failToast) {
              toast(failToast, {
                type: toast.TYPE.ERROR,
                autoClose: 3000,
              });
            }
            reject(response || "Error");
          }
        })
        .catch((error) => {
          toast("Network or server error", {
            type: toast.TYPE.ERROR,
            autoClose: 3000,
          });
          reject(error);
        });
    });
  }

  /**
   * Makes a DELETE request to the specified URL, optionally dispatches an action, shows toasts, and resolves with the response.
   *
   * @param {object} options - The options for the DELETE request.
   * @param {string} options.url - The URL to make the DELETE request to.
   * @param {string} [options.actionType] - The action type to dispatch with the response.
   * @param {function} [options.modifyResponse] - A function to modify the response before dispatching and resolving.
   * @param {string} [options.successToast] - A success message to show as a toast notification.
   * @param {string} [options.failToast] - A failure message to show as a toast notification.
   * @returns {Promise} A promise that resolves with the response.
   */
  delete({ url, actionType, modifyResponse, successToast, failToast }) {
    return new Promise((resolve, reject) => {
      API.delete(url)
        .then((response) => {
          if (response && (response.code === undefined || response.code === 200)) {
            // Apply the modifyResponse function if provided
            if (modifyResponse && typeof modifyResponse === "function") {
              response = modifyResponse(response);
            }

            if (actionType) {
              Dispatcher.dispatch({
                actionType: actionType,
                payload: response,
              });
            }

            // Display toast if toast message is specified
            if (successToast) {
              toast(successToast, {
                type: toast.TYPE.SUCCESS,
                autoClose: 3000,
                preventDuplicated: true,
              });
            }
            resolve(response);
          } else {
            // Display toast if toast message is specified
            if (failToast) {
              toast(failToast, {
                type: toast.TYPE.ERROR,
                autoClose: 3000,
                preventDuplicated: true,
              });
            }
            reject(response || "Error");
          }
        })
        .catch((error) => {
          toast("Network or server error", {
            type: toast.TYPE.ERROR,
            autoClose: 3000,
          });
          reject(error);
        });
    });
  }
}

export default new GeneralUtils();
