import React from 'react';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './routes';
import * as serviceWorker from './serviceWorker';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Highcharts from 'highcharts';
import $ from 'jquery';
import { polyfill } from 'es6-promise';
import { checkTokenOnFocus } from './utils/tokenValidator';

toast.configure()
polyfill();
checkTokenOnFocus();

Highcharts.setOptions({
  global: {
    useUTC: false
  }
});

if (!String.prototype.includes) {
  // eslint-disable-next-line no-extend-native
  String.prototype.includes = function (search, start) {
    if (typeof start !== 'number') {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}

//Polyfill for IE 8/9
if (!Object.keys) {
  Object.keys = (function () {
    var hasOwnProperty = Object.prototype.hasOwnProperty,
      hasDontEnumBug = !({ toString: null }).propertyIsEnumerable('toString'),
      dontEnums = [
        'toString',
        'toLocaleString',
        'valueOf',
        'hasOwnProperty',
        'isPrototypeOf',
        'propertyIsEnumerable',
        'constructor'
      ],
      dontEnumsLength = dontEnums.length;

    return function (obj) {
      if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
        throw new TypeError('Object.keys called on non-object');
      }

      var result = [], prop, i;

      for (prop in obj) {
        if (hasOwnProperty.call(obj, prop)) {
          result.push(prop);
        }
      }

      if (hasDontEnumBug) {
        for (i = 0; i < dontEnumsLength; i++) {
          if (hasOwnProperty.call(obj, dontEnums[i])) {
            result.push(dontEnums[i]);
          }
        }
      }
      return result;
    };
  }());
}

//Polyfill for IE 11
if (!Object.entries) {
  Object.entries = function (obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array
    while (i--)
      resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
}

if (Number.parseInt === undefined) {
  Number.parseInt = window.parseInt;
}
window.$ = $

const MAINTENANCE_CONFIG = {
  active: false, // Whether maintenance mode is active
  message: '', // Displays a message (default is "We plan to be back soon!"")
  countdownActive: false, // controls whether countdown component is visible
  timeTillDate: "23 11 2020, 09:00 am", // Sets countdown goal
  timeFormat: "DD MM YYYY, h:mm a", // Countdown goal format
}

ReactDOM.render(
  <Router>
    <Routes maintenanceData={MAINTENANCE_CONFIG} />
  </Router>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
