import React, { Component } from "react";
import "./SensorListing.scss";
import AdminStore from "../../stores/adminStore";
import AdminActions from "../../actions/adminActions";
import _ from "lodash";
import moment from "moment";
import { DocumentTitle } from "../../components/DocumentTitle";
import { SearchSelect } from "../../components/SearchSelect";

import { Button } from 'antd';
import { Icon } from '../../components/Icon';

class SensorListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      available_buildings: [],
      available_estates: [],
      selected_building: { name: "All Buildings" },
      selected_estate: { name: "All Estates" },
      sensors: AdminStore.getSensorStats(),
      loading: true,
      sortBy: "status",
      sortDirection: "asc",
      searchInput: "",
    };
    this._onStatusChange = this._onStatusChange.bind(this);
    this._onSensorFilterInput = this._onSensorFilterInput.bind(this);
    this._setBuildingFilter = this._setBuildingFilter.bind(this);
    this._setEstateFilter = this._setEstateFilter.bind(this);
    this._triggerDownload = this._triggerDownload.bind(this);
  }
  _onStatusChange() {
    let sensors = AdminStore.getSensorStats();
    let available_buildings = [];
    let available_building_names = [];
    let available_estates = [];
    let available_estate_names = [];

    for (let i = 0; i < sensors.length; i++) {
      if (!available_building_names.includes(sensors[i].building_name)) {
        available_building_names.push(sensors[i].building_name);
      }
      if (!available_estate_names.includes(sensors[i].estate_name)) {
        available_estate_names.push(sensors[i].estate_name);
      }
    }

    for (let i = 0; i < available_building_names.length; i++) {
      available_buildings.push({ name: available_building_names[i] });
    }
    for (let i = 0; i < available_estate_names.length; i++) {
      available_estates.push({ name: available_estate_names[i] });
    }

    available_buildings = _.orderBy(available_buildings, "name", "asc");
    available_buildings.unshift({ name: "All Buildings" });

    available_estates = _.orderBy(available_estates, "name", "asc");
    available_estates.unshift({ name: "All Estates" });

    this.setState({
      sensors: sensors,
      available_buildings: available_buildings,
      available_estates: available_estates,
      loading: false,
    });
  }
  componentWillMount() {
    AdminStore.addChangeListener(this._onStatusChange);
  }
  componentDidMount() {
    AdminActions.getHealthChecks(true);
  }
  componentWillUnmount() {
    AdminStore.removeChangeListener(this._onStatusChange);
  }
  _changeSortBy(sortBy) {
    if (this.state.sortBy !== sortBy) {
      this.setState({ sortBy: sortBy, sortDirection: "asc" });
    } else {
      if (this.state.sortDirection === "asc") {
        this.setState({ sortDirection: "desc" });
      } else {
        this.setState({ sortDirection: "asc" });
      }
    }
  }
  _onSensorFilterInput(event) {
    this.setState({ searchInput: event.target.value });
  }
  _setBuildingFilter(building) {
    this.setState({ selected_building: building });
  }
  _setEstateFilter(estate) {
    let sensors = AdminStore.getSensorStats();
    let available_buildings = [];
    let available_building_names = [];
    for (let i = 0; i < sensors.length; i++) {
      if (
        !available_building_names.includes(sensors[i].building_name) &&
        (sensors[i].estate_name === estate.name ||
          estate.name === "All Estates")
      ) {
        available_building_names.push(sensors[i].building_name);
      }
    }
    for (let i = 0; i < available_building_names.length; i++) {
      available_buildings.push({ name: available_building_names[i] });
    }

    available_buildings = _.orderBy(available_buildings, "name", "asc");
    available_buildings.unshift({ name: "All Buildings" });

    this.setState({
      selected_estate: estate,
      available_buildings: available_buildings,
      selected_building: { name: "All Buildings" },
    });
  }
  _triggerDownload() {
    var html = document.getElementById("dataTable");
    let csv = [];
    let rows = html.querySelectorAll("table tr");

    for (let i = 0; i < rows.length; i++) {
      let row = [],
        cols = rows[i].querySelectorAll("td, th");

      for (let j = 0; j < cols.length; j++) row.push(cols[j].innerText);

      csv.push(row.join(","));
    }
    let csvFile;
    let downloadLink;

    // CSV FILE
    csvFile = new Blob([csv.join("\n")], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download =
      "OPNBuildings Sensor Listing - " +
      moment().format("DD MMM, YYYY") +
      ".csv";
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
  getSensorTable() {
    const getSortIcon = (column) => {
      if (this.state.sortBy === column && this.state.sortDirection === "asc")
        return (
          <ion-icon
            name="arrow-dropup"
            size="small"
            style={{
              verticalAlign: "top",
              float: "right",
              position: "absolute",
            }}
          />
        );
      if (this.state.sortBy === column && this.state.sortDirection === "desc")
        return (
          <ion-icon
            name="arrow-dropdown"
            size="small"
            style={{
              verticalAlign: "top",
              float: "right",
              position: "absolute",
            }}
          />
        );
      return null;
    };

    let sensors = _.orderBy(
      this.state.sensors,
      this.state.sortBy,
      this.state.sortDirection
    );
    if (this.state.selected_estate.name !== "All Estates") {
      let estate_name = this.state.selected_estate.name;
      sensors = _.filter(sensors, function (obj) {
        return (
          obj.estate_name.toLowerCase().indexOf(estate_name.toLowerCase()) !==
          -1
        );
      });
    }
    if (this.state.selected_building.name !== "All Buildings") {
      let building_name = this.state.selected_building.name;
      sensors = _.filter(sensors, function (obj) {
        return (
          obj.building_name
            .toLowerCase()
            .indexOf(building_name.toLowerCase()) !== -1
        );
      });
    }

    let searchValue = this.state.searchInput;
    if (searchValue && searchValue.length > 0) {
      sensors = _.filter(sensors, function (obj) {
        return obj.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
      });
    }

    const getLastDataCell = (ts_last_data) => {
      try {
        return (
          <div>
            <div>
              {moment(ts_last_data).fromNow()} -{" "}
              {moment(ts_last_data).format("YYYY-MM-DD, HH:MM")}
            </div>
          </div>
        );
      } catch {
        return "-";
      }
    };

    return (
      <div className="card bd-0 shadow-base mg-t-20 overflow-auto">
        <table className="table mg-b-0 table-contact" id="dataTable">
          <thead>
            <tr>
              <th
                className="wd-25p tx-mont tx-medium"
                style={{ cursor: "pointer" }}
                onClick={this._changeSortBy.bind(this, "building_name")}
              >
                Building {getSortIcon("building_name")}
              </th>
              <th
                className="wd-25p tx-mont tx-medium"
                style={{ cursor: "pointer" }}
                onClick={this._changeSortBy.bind(this, "location")}
              >
                Location {getSortIcon("location")}
              </th>
              <th
                className="wd-25p tx-10-force tx-mont tx-medium tx-center"
                style={{ cursor: "pointer" }}
                onClick={this._changeSortBy.bind(this, "name")}
              >
                {" "}
                Model &amp; ID {getSortIcon("name")}
              </th>
              <th
                className="wd-25p tx-10-force tx-mont tx-medium tx-center"
                style={{ cursor: "pointer" }}
                onClick={this._changeSortBy.bind(this, "ts_last_data")}
              >
                {" "}
                Last Online {getSortIcon("ts_last_data")}
              </th>
            </tr>
          </thead>
          <tbody>
            {sensors.map((sensor, key) => {
              let locationClass = "tx-inverse";
              let lastDataClass = "tx-inverse";

              if (sensor.location === "" || sensor.location === null) {
                locationClass = "tx-danger";
              }

              return (
                <tr key={key}>
                  <td className="valign-middle" title={sensor.uuid}>
                    <span className="tx-inverse" title={sensor.uuid}>
                      {sensor.building_name}
                    </span>
                  </td>
                  <td className="valign-middle" title={sensor.uuid}>
                    <span className={locationClass} title={sensor.uuid}>
                      {sensor.location === "" || sensor.location === null
                        ? "No Location Assigned"
                        : sensor.location}
                    </span>
                  </td>
                  <td className="valign-middle tx-center" title={sensor.uuid}>
                    <span className="tx-inverse" title={sensor.uuid}>
                      {sensor.name}
                    </span>
                  </td>
                  <td className="valign-middle tx-center">
                    <span className={lastDataClass}>
                      {getLastDataCell(sensor.ts_last_data)}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
  render() {
    return (
      <div className="br-mainpanel pd-15">
        <DocumentTitle title="Sensor Inventory" />
        <div className="br-pagetitle mg-b-0">
          <div>
            <h4>View All Sensors</h4>
            <p className="mg-b-0">
              Inventory of sensors on the system, their mapped locations and
              connectivity status.
            </p>
          </div>
        </div>
        <div>
          <div className="col-12">
            <div>
              <div className="br-pagetitle pd-x-0 pd-b-0 mg-b-10 flex-wrap">
                <span className="col-12 col-md d-flex pd-0 flex-wrap">
                  <span className="col-4 pd-l-0">
                    <div className="input-group">
                      <div className="input-group-prepend" style={{ height:"38px" }}>
                        <span className="input-group-text">
                          <ion-icon name="search" size="small" />
                        </span>
                      </div>
                      <input
                        defaultValue={this.state.searchInput}
                        style={{ height:"38px" }}
                        type="text"
                        className="form-control"
                        placeholder="Search By Sensor Id"
                        onChange={this._onSensorFilterInput}
                      />
                    </div>
                  </span>
                  <span className="col-4 pd-l-0">
                    <SearchSelect
                      limit={100}
                      options={this.state.available_estates}
                      placeholder={"Select Estate"}
                      defaultValue={this.state.selected_estate}
                      actionOnSelectedOption={this._setEstateFilter}
                    />
                  </span>
                  <span className="col-4 pd-l-0">
                    <SearchSelect
                      limit={100}
                      options={this.state.available_buildings}
                      placeholder={"Select Building"}
                      defaultValue={this.state.selected_building}
                      actionOnSelectedOption={this._setBuildingFilter}
                    />
                  </span>
                </span>
                <Button
                  className="button blue"
                  icon={<Icon name="Download" color={'#fff'} size={18} />}
                  size="small"
                  onClick={this._triggerDownload}
                  style={{ height: '38px' }}
                >Export</Button>
              </div>
              {this.getSensorTable()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SensorListing;
