import React from "react";

import styles from "./Zones.module.scss";
import _ from "lodash";

//components
import { DocumentTitle } from "../../../components/DocumentTitle";
import { LogoSpinner } from "../../../components/LogoSpinner";
import { AntTable } from "../../../components/AntTable";
import GeneralUtils from "../../../utils/GeneralUtils";
import GeneralStore from "../../../stores/generalStore";
import ZonesOverviewStore from "../../../stores/zonesOverviewStore";
import ZonesOverviewActions from "../../../actions/zonesOverviewActions";

class Zones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      ts_start: GeneralStore.getStartDate(),
      ts_end: GeneralStore.getEndDate(),
      loading: true,
    };
    this.onZonesFetch = this.onZonesFetch.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.rowClickHandler = this.rowClickHandler.bind(this);
  }

  componentWillMount() {
    ZonesOverviewStore.addZonesOverviewFetchListener(this.onZonesFetch);
    GeneralStore.addChangeListener(this.onDateChange);
  }

  componentWillUnmount() {
    ZonesOverviewStore.removeZonesOverviewFetchListener(this.onZonesFetch);
    GeneralStore.removeChangeListener(this.onDateChange);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const start = GeneralUtils.getFormattedDate(this.state.ts_start);
    const end = GeneralUtils.getFormattedDate(this.state.ts_end, true);

    this.setState(
      {
        loading: true,
      },
      () => ZonesOverviewActions.getZonesOverview(start, end)
    );
  }

  onZonesFetch() {
    const data = ZonesOverviewStore.getZonesOverview();
    this.setState({ data: data, loading: false });
  }

  onDateChange() {
    this.setState(
      {
        ts_start: GeneralStore.getStartDate(),
        ts_end: GeneralStore.getEndDate(),
      },
      () => this.fetchData()
    );
  }

  rowClickHandler(entry) {
    const building_id = _.get(entry, "building.id");
    const entity_id = _.get(entry, "entity.id");

    if (building_id && entity_id) {
      sessionStorage.setItem("zoneLastLoc", this.props.location.pathname);
      this.props.history.push("/zones/" + entity_id);
    }
  }

  getTable() {
    const columns = [
      {
        title: 'Building Name',
        dataIndex: ['building', 'name'],
        key: 'buildingName',
        defaultSortOrder: 'ascend',
        width: '15%',
        filterable: true,
      },
      {
        title: 'Zone Name',
        dataIndex: ['zone', 'name'],
        key: 'zoneName',
        width: '15%',
        filterable: true,
      },
      {
        title: 'Description',
        dataIndex: ['zone', 'description'],
        key: 'zoneDescription',
        width: '20%',
        filterable: true,
      },
      {
        title: 'Temperature',
        dataIndex: ['wellness', 'temperature'],
        key: 'wellnessTemperature',
        render: (text) => text !== null ? GeneralUtils.getFormattedNumberWithUnit(text, " °C", 1) : null,
        width: '10%',
      },
      {
        title: 'CO2',
        dataIndex: ['wellness', 'co2'],
        key: 'wellnessCo2',
        render: (text) => text !== null ? GeneralUtils.getFormattedNumberWithUnit(text, " ppm", 0) : null,
        width: '10%',
      },
      {
        title: 'Humidity',
        dataIndex: ['wellness', 'humidity'],
        key: 'wellnessHumidity',
        render: (text) => text !== null ? GeneralUtils.getFormattedNumberWithUnit(text, "%", 0) : null,
        width: '10%',
      },
      {
        title: 'PM2.5',
        dataIndex: ['wellness', 'pm25'],
        key: 'wellnessPm25',
        render: (text) => text !== null ? GeneralUtils.getFormattedNumberWithUnit(text, " mcg/m3", 0) : null,
        width: '10%',
      },
      {
        title: 'Light',
        dataIndex: ['wellness', 'lux'],
        key: 'wellnessLux',
        render: (text) => text !== null ? GeneralUtils.getFormattedNumberWithUnit(text, "%", 0) : null,
        width: '10%',
      },
    ];

    return <AntTable
      columns={columns}
      dataSource={this.state.data}
      hasInputFilter={true}
      virtual
      scroll={{ x: 1000 }}
      rowKey={(record) => `${record.zone.id}`}
      onRow={(record) => ({
        onClick: () => this.rowClickHandler(record),
      })}
    />
  }

  render() {


    return (
      <div
        id="Zones"
        className="br-mainpanel br-profile-page floorplan-background"
      >
        <DocumentTitle title="Zones Overview" />
        <LogoSpinner loading={this.state.loading} />
        <div className="br-container">
          <div className="row">
            <div className={`col mg-t-30 d-flex ${styles.tableWrapper}`}>
              {this.getTable()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Zones;
